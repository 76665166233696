import React from "react";
import SocialMediaBanner from "./partials/social_media_banner";

export default function ContactMe() {
  return (
    <section className="py-14 text-slate-200 px-8 min-h-screen">
      {/* Email */}
      <div className="mb-8">
        <h4 className="text-lg font-semibold">Email:</h4>
        <p><a href="mailto:matthew.whallett@doctors.org.uk" className="underline">matthew.whallett@doctors.org.uk</a></p>
      </div>

      {/* Working hours */}
      <div className="mb-8">
        <h4 className="text-lg font-semibold">Working Hours:</h4>
        <table className="w-full border-collapse">
          <tbody>
            <tr className="border-b">
              <td className="py-2">Monday - Friday</td>
              <td className="py-2">09:00 - 17:00</td>
            </tr>
            <tr className="border-b">
              <td className="py-2">Saturday - Sunday</td>
              <td className="py-2">Off</td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Social Links */}
      <SocialMediaBanner />
    </section>
  );
};
