import React, { useEffect, useState } from "react";

// Define the time-to-name mapping
const timeToNameMapping = {
  "15:15": "Lo",
  "15:45": "Ed",
  "16:15": "Ame",
  "16:45": "George",
  "17:15": "Euge",
  "17:45": "Ed",
  "18:15": "George",
  "18:45": "Lo",
  "19:15": "Ed",
  "19:45": "Ame",
  "20:15": "Ed",
  "20:45": "George",
  "21:15": "Lo",
  "21:45": "Lo",
  "22:15": "Ed",
  "22:45": "Ame",
  "23:15": "Ame",
  "23:45": "Dan",
  "00:15": "Dan",
  "00:45": "Lo",
  "01:15": "Ame",
  "01:45": "Ed",
  "02:15": "Joe SR",
  "02:45": "Joe SR",
  "03:15": "Euge",
  "03:45": "Jean-Come",
  "04:15": "Lo",
  "04:45": "Ame",
  "05:15": "Lo",
  "05:45": "Lo",
  "06:15": "Euge",
};

const App = () => {
  const [currentName, setCurrentName] = useState("");

  useEffect(() => {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = now.getMinutes();
    const currentTime = `${hours}:${String(minutes).padStart(2, "0")}`;

    // Determine the time key based on the current minutes
    let timeKey;
    if (minutes >= 15 && minutes < 45) {
      timeKey = `${hours}:15`;
    } else if (minutes >= 45 || minutes < 15) {
      const nextHour =
        minutes >= 45
          ? hours === "23"
            ? "00"
            : String(Number(hours) + 1).padStart(2, "0")
          : hours;
      timeKey = `${nextHour}:45`;
    }

    // Determine if we should display the placeholder
    const isPlaceholderTime = currentTime < "15:15" && currentTime > "06:45";
    const newName = isPlaceholderTime
      ? "The game is currently not active"
      : `Maddy is currently looking at ${timeToNameMapping[timeKey]}`;
    setCurrentName(newName);
  }, []);

  return (
    <>
      <div
        style={{
          textAlign: "center",
          fontSize: "24px",
          color: "white",
          marginTop: 50,
          marginBottom: 50,
        }}
      >
        <h1>{currentName}</h1>
      </div>
      <div
        style={{
          textAlign: "center",
          fontSize: "18px",
          paddingLeft: 50,
          paddingRight: 50,
          color: "white",
          paddingBottom: "50px",
        }}
      >
        <a href="https://maps.app.goo.gl/n5KZbrMYsPwsnT6Y9">
          <u>Google maps route</u>
        </a>
      </div>
      <div
        style={{
          textAlign: "left",
          fontSize: "18px",
          paddingLeft: 50,
          paddingRight: 50,
          color: "white",
          paddingBottom: "50px",
        }}
      >
        <h1>Pub crawl rules</h1>
        <br />
        <p>
          In the darkening days of the Fellowship, there arose a dire peril
          named Maddy, whose gaze was likened to the baleful Eye of Sauron,
          seeking ever to corrupt our noble band. Each member stood as a
          stalwart protector of the realm, yet within us lay the seeds of doubt
          and temptation. Amongst us was Ed, who, like Boromir of old, wrestled
          with the shadows cast by Maddy's insidious eye.
        </p>
        <br />
        <p>
          At random, Maddy’s fell gaze would settle upon one of us, casting the
          accusation of bearing the One Ring. In the turn of every thirty
          minutes, upon the hour and half-hour, the burdened Ring-bearer must
          relinquish their charge, passing the ring to another, lest the weight
          become too great. In between these moments, at the quarter-hour and
          three-quarter past, Maddy’s eye would shift, seeking new souls to
          ensnare.
        </p>
        <br />
        <h2>Reward for the Ring-bearer </h2>
        <br />
        <l>
          <li>
            The bearer of the ring is granted the boon to purchase drinks from
            the Fellowship’s shared kitty.
          </li>
        </l>
        <br />
        <h2> Punishments for the Gazed-Upon Ring-bearer </h2>
        <br />
        <p>
          {" "}
          Should the Ring-bearer fall under Maddy’s accusing eye, they are
          doomed to a fate akin to that of a Ringwraith, as if pierced by a
          Morgul blade:
        </p>
        <br />
        <l>
          <li>
            {" "}
            They may drink only dark-hued draughts or those tainted with
            blackcurrant for the remainder of the night.
          </li>
          <li> They must don sunglasses, shunning the light of day. </li>
          <li>
            Their voice becomes a weapon of dread, capable of paralysing any
            mortal with fear, twice per person, enforced by tokens. The victim
            must remain still for fifteen minutes, accepting any ‘medicine’
            proffered by their companions.{" "}
          </li>
          <li>
            No man can defeat them in challenge – they may confront the
            Ring-bearer to a test of resolve (a drinking contest known as a Bolt
            off), once per person, policed by tokens. Should they triumph, the
            Ring-bearer is turned into a wraith, compelled to pass the ring
            onward once more.{" "}
          </li>
        </l>
        <br />
        <p>
          This ritual binds itself to the sacred bonds of matrimony, symbolised
          by the ring, and mirrors Ed’s perilous journey, ever shadowed by
          Maddy’s corruption.
        </p>
        <br />
        <p>
          {" "}
          Thus, the Fellowship must remain vigilant and true, ever wary of the
          lurking eye and the heavy burden of the One Ring.
        </p>
      </div>
    </>
  );
};

export default App;
