import React from "react";
import CodeMed from "./../assets/course_images/CodeMed.jpeg";
import CS50x from "./../assets/course_images/CS50x.png";
import MLAZ from "./../assets/course_images/MLAZ.png";
import Swift from "./../assets/course_images/Swift.png";
import network_chuck from "./../assets/course_images/network_chuck.png";
import AWS from "./../assets/course_images/AWS.png";

import CIcon from "./../assets/coding_languages/icon/C.ico";
import HTMLCSSJSIcon from "./../assets/coding_languages/icon/HTMLCSSJS.ico";
import JavaIcon from "./../assets/coding_languages/icon/Java.ico";
import PythonIcon from "./../assets/coding_languages/icon/Python.ico";
import SwiftIcon from "./../assets/coding_languages/icon/Swift.ico";

export default function ComputerScienceEducation() {
  const images = [
    {
      src: CodeMed,
      title: "CodeMed’s Java Course",
      body: "I completed this wonderful course in 2020 and was invited back to be part of the teaching faculty.",
      link: "https://codemed.co.uk/",
    },
    {
      src: network_chuck,
      title: "Network Chuck",
      body: "I worked my way through Network Chuck’s Free CCNA course. Absolutely fantastic content!",
      link: "https://www.youtube.com/playlist?list=PLIhvC56v63IJVXv0GJcl9vO5Z6znCVb1P",
    },
    {
      src: CS50x,
      title: "CS50x: Introduction to Computer Science",
      body: "I would recommend this course to anyone starting out learning computer science",
      link: "https://pll.harvard.edu/course/cs50-introduction-computer-science?delta=0",
    },
    {
      src: MLAZ,
      title: "Machine Learning A-Z: AI, Python & R",
      body: "I learnt the basics of machine learning and artifical intelligence with this course",
      link: "https://www.udemy.com/course/machinelearning/",
    },
    {
      src: Swift,
      title: "iOS & Swift – The Complete iOS App Development Bootcamp",
      body: "Angela Yu’s course on iOS App development was amazing",
      link: "https://www.udemy.com/course/ios-13-app-development-bootcamp/?couponCode=ST12MT030524",
    },
    {
      src: AWS,
      title: "Ultimate AWS Certified Cloud Practitioner Course",
      body: "Stephane Maarek’s course was a great way to learn the basics of AWS",
      link: "https://www.udemy.com/share/103a093@FEHbXRD37NxB8_aPpLywwdsmJpww3ei55SZ7rApdjlI0wA6l969iNeBMjs-m___zTA==/",
    },
  ];

  function LanguageIcon({ icon, name }) {
    return (
      <div className="relative flex flex-col items-center justify-center mr-4">
        <div className="group">
          <div className="w-20 h-20 flex items-center justify-center rounded-full bg-slate-800">
            <img
              src={icon}
              alt={name}
              className="w-10 h-10 rounded-full transition-opacity duration-300 hover:opacity-100"
            />
          </div>
          <div className="absolute hidden opacity-0 transition-opacity duration-500 group-hover:opacity-100 group-hover:block text-slate-200 p-2 mt-2 items-center justify-center">
            <div className="opacity-0 group-hover:opacity-100">{name}</div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <section className="py-16 text-slate-200 text-center">
      <p className="text-xl font-bold mb-8">Computer science education</p>
      <div className="mb-12 flex flex-col gap-8 justify-center items-center">
        <div className="flex flex-wrap gap-14 mb-16 justify-center items-center">
          <LanguageIcon icon={CIcon} name="C" />
          <LanguageIcon icon={JavaIcon} name="Java" />
          <LanguageIcon icon={SwiftIcon} name="Swift" />
          <LanguageIcon icon={PythonIcon} name="Python" />
          <LanguageIcon icon={HTMLCSSJSIcon} name="HTML, CSS & JS" />
        </div>
        <p className="">I have a working knowledge of:</p>
        <ul className="list-disc list-inside">
          <li>Networking</li>
          <li>Amazon Web Services</li>
          <li>Machine learning and artificial intelligence</li>
        </ul>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 px-4 gap-8 justify-center items-center">
        {images.map((image, index) => (
          <div key={index} className="relative">
            <img
              src={image.src}
              alt={image.title}
              className="w-full h-auto transition-opacity duration-300 hover:opacity-90"
            />
            <div className="absolute px-4 inset-0 flex flex-col items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-300 bg-black bg-opacity-50">
              <a href={image.link} target="_blank" rel="noreferrer">
                <p className="text-slate-200 font-bold hover:underline">
                  {image.title}
                </p>
              </a>
              <p className="text-slate-200">{image.body}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}
