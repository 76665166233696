import React, { useState } from "react";

const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <nav className="w-full flex flex-grow py-4 text-slate-200 px-8">
      <div className="w-1/4 font-extrabold text-xl">
        <a href="/">Matthew Whallett</a>
      </div>
      <div className="w-3/4 container mx-auto flex justify-end items-center">
        {/* Burger button */}
        <button
          className="block md:hidden"
          onClick={toggleMobileMenu}
        >
          <svg
            className="h-6 w-6 fill-current text-slate-200"
            viewBox="0 0 24 24"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M21 6H3V5h18v1zM21 11H3v1h18v-1zM3 18h18v-1H3v1z"
            />
          </svg>
        </button>

        {/* Mobile menu */}
        <div className={`md:hidden ${isMobileMenuOpen ? "" : "hidden"}`}>
          <ul className="flex flex-col">
            <li className="mx-4">
              <a href="/" className="hover:text-slate-400">
                Home
              </a>
            </li>
            <li className="mx-4">
              <a href="/medical-career" className="hover:text-slate-400">
                Medical career
              </a>
            </li>
            <li className="mx-4">
              <a href="/computer-science" className="hover:text-slate-400">
                Computer science
              </a>
            </li>
            <li className="mx-4">
              <a href="/contact-me" className="hover:text-slate-400">
                Contact me
              </a>
            </li>
          </ul>
        </div>

        {/* Regular menu */}
        <ul className="hidden md:flex space-x-8">
          <li className="mx-4">
            <a href="/" className="hover:text-slate-400">
              Home
            </a>
          </li>
          <li className="mx-4">
            <a href="/medical-career" className="hover:text-slate-400">
              Medical career
            </a>
          </li>
          <li className="mx-4">
            <a href="/computer-science" className="hover:text-slate-400">
              Computer science
            </a>
          </li>
          <li className="mx-4">
            <a href="/contact-me" className="hover:text-slate-400">
              Contact me
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
