import React from "react";
import facebook from "./../../assets/social_media/facebook.svg";
import instagram from "./../../assets/social_media/instagram.svg";
import linkedin from "./../../assets/social_media/linkedin.svg";
import twitter from "./../../assets/social_media/twitter.svg";

export default function SocialMediaBanner() {
  return (
    <div className="flex flex-wrap gap-2 justify-center">
      <a href="https://www.facebook.com/biW2ke4/" target="tab">
        <img src={facebook} alt="Facebook" className="h-6 w-6" />
      </a>
      <a href="https://www.instagram.com/mat_wallet/" target="tab">
        <img src={instagram} alt="Instagram" className="h-6 w-6" />
      </a>
      <a href="https://www.linkedin.com/in/matthew-whallett/" target="tab">
        <img src={linkedin} alt="LinkedIn" className="h-6 w-6" />
      </a>
      <a href="https://twitter.com/mwhallett" target="tab">
        <img src={twitter} alt="Twitter" className="h-6 w-6" />
      </a>
    </div>
  );
}
