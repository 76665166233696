import { Routes, Route, BrowserRouter } from "react-router-dom";
import React from "react";
import "./App.css";

import Navbar from "./components/partials/navbar";
import BottomBar from "./components/partials/bottombar";
import Home from "./components/home";
import MedicalCareer from "./components/medical_career";
import ComputerScience from "./components/computer_science";
import ComputerScienceProjects from "./components/computer_science_projects";
import ComputerScienceEducation from "./components/computer_science_education";
import ContactMe from "./components/contact_me";
import PPA from "./components/ppa";
import Stag from "./components/stag";

function App() {
  return (
    <BrowserRouter>
      <div className="bg-gradient-to-b from-[#455A64] via-[#00796B] to-[#1c2428]">
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/medical-career" element={<MedicalCareer />} />
          <Route path="/computer-science" element={<ComputerScience />} />
          <Route
            path="/computer-science/projects"
            element={<ComputerScienceProjects />}
          />
          <Route
            path="/computer-science/education"
            element={<ComputerScienceEducation />}
          />
          <Route path="/contact-me" element={<ContactMe />} />
          <Route path="/prizes-publications-audits" element={<PPA />} />
          <Route path="/stag" element={<Stag />} />
        </Routes>
        <BottomBar />
      </div>
    </BrowserRouter>
  );
}

export default App;
