import React from "react";

const ProfessionalSection = () => {
  return (
    <div className="mb-8">
      <h4 className="text-lg font-semibold mb-2">Professional examinations & qualifications</h4>
      <table className="table-auto w-full">
        <tbody>
          <tr>
            <td className="border px-4 py-2">2023</td>
            <td className="border px-4 py-2">MRCP Part 1</td>
          </tr>
          <tr>
            <td className="border px-4 py-2">2022</td>
            <td className="border px-4 py-2">Introduction to Good Clinical Practice (GCP)</td>
          </tr>
          <tr>
            <td className="border px-4 py-2">2022</td>
            <td className="border px-4 py-2">
              <a href="https://www.medical-interviews.co.uk/product/teach-the-teachertrain-the-trainer-course" className="text-blue-500">Teach The Teacher / Train The Trainer Course for Healthcare Professionals</a>
            </td>
          </tr>
          <tr>
            <td className="border px-4 py-2">2021</td>
            <td className="border px-4 py-2">Advanced life support</td>
          </tr>
          <tr>
            <td className="border px-4 py-2">2020</td>
            <td className="border px-4 py-2">
              <a href="https://learn.heart.org/lms/nihss" className="text-blue-500">NIH Stroke Scale International (NIHSSI) Test Course</a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const PrizesSection = () => {
  return (
    <div className="mb-8">
      <h4 className="text-lg font-semibold mb-2">Prizes</h4>
      <table className="table-auto w-full">
        <tbody>
          <tr>
            <td className="border px-4 py-2">2017</td>
            <td className="border px-4 py-2"><em>Medical school prize</em> Best group at the University of Exeter Medical Humanities Conference</td>
          </tr>
          <tr>
            <td className="border px-4 py-2">2017</td>
            <td className="border px-4 py-2"><em>Medical school prize</em> Highly commended in the category of Doctors as Teachers</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

// Define PublicationsSection, AuditsSection similarly

const PPA = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <ProfessionalSection />
      <PrizesSection />
      {/* Add PublicationsSection, AuditsSection components */}
    </div>
  );
};

export default PPA;
