import React from "react";
import SocialMediaBanner from "./social_media_banner"

export default function BottomBar() {
  return (
    <footer className=" text-white py-6 px-4 bg-slate-950">
      <div className="container mx-auto flex justify-between items-center text-slate-200">
        <div className="flex flex-col gap-y-2">
            <p>Dr Matthew Whallett BMBS BSc</p>
          <p className="text-slate-500">&copy; 2024 Matthew Whallett. All rights reserved.</p>
        </div>
        <div className="flex gap-x-5 align-middle items-center justify-center">
            <div className="rounded-xl bg-slate-500 p-2">
                <SocialMediaBanner />
                </div>
              <a href="/contact-me" className="inline-block">Contact Me</a>
        </div>
      </div>
    </footer>
  );
}
