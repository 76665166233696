import React from "react";

import BMBS from './../assets/career_images/BMBS.jpeg'
import BSc from './../assets/career_images/BSc.jpeg'
import CTF from './../assets/career_images/CTF.jpeg'
import FY from './../assets/career_images/FY.jpeg'
import FY3 from './../assets/career_images/FY3.jpeg'
import IMT from './../assets/career_images/IMT.png'

const SectionWithImage = ({ image, children }) => (
  <div className="wp-block-group alignfull has-background">
    <div className="container mx-auto px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        {/* Mobile format: Image above text */}
        <div className="flex flex-col items-center justify-center md:flex-row">
          <img src={image} alt="Section Image" className="w-40 h-auto mb-4 md:mr-4 md:mb-0" />
          <div>{children}</div>
        </div>
      </div>
    </div>
  </div>
);


const ColumnSection = ({ children }) => (
  <div className="wp-block-jetpack-layout-grid column1-desktop-grid__span-6 column1-desktop-grid__start-2 column1-desktop-grid__row-1 column2-desktop-grid__span-3 column2-desktop-grid__start-9 column2-desktop-grid__row-1 column1-tablet-grid__span-5 column1-tablet-grid__row-1 column2-tablet-grid__span-2 column2-tablet-grid__start-7 column2-tablet-grid__row-1 column1-mobile-grid__span-4 column1-mobile-grid__row-1 column2-mobile-grid__span-4 column2-mobile-grid__row-2">
    {children}
  </div>
);

const Description = ({ text }) => (
  <p className="text-lg">{text}</p>
);

const Institution = ({ name }) => <p className="">{name}</p>;

const Location = ({ location }) => <p className="">{location}</p>;

const Dates = ({ dates }) => <p className="">{dates}</p>;

const Spacer = () => <div className="h-5"></div>;

export default function MedicalCareer() {
  return (
    <div className="text-slate-200">
      {/* Internal medical training section */}
      <SectionWithImage image={IMT}>
        <ColumnSection>
          <Description text="Internal medical training" />
          <Description text="I am currently undertaking internal medical training at Sandwell and West Birmingham Hospitals NHS Trust since August 2023, and I have rotations in respiratory medicine, cardiology, intensive care medicine, haematology, gastroenterology and elderly care medicine." />
        </ColumnSection>
        <Spacer />
        <ColumnSection>
          <Institution name="Sandwell and West Birmingham Hospitals NHS Trust" />
          <Location location="Birmingham, West Midlands" />
          <Spacer />
          <Dates dates="August 2023 - present" />
        </ColumnSection>
      </SectionWithImage>

      {/* Digital Clinical Fellow & Clinical Teaching Fellow section */}
      <SectionWithImage image={CTF}>
        <ColumnSection>
          <Description text="Digital Clinical Fellow & Clinical Teaching Fellow" />
          <Description text="As a digital clinical fellow at the Dudley Group NHS Foundation Trust, I had a range of responsibilities. Firstly, I worked as a clinical teaching fellow, assisting in delivering the University of Birmingham’s medical student curriculum where I delivered regular lectures and bedside teaching. Additionally, I spent time collaborating with the hospital’s media team to develop new clinical systems, such as the clinical compass. I was also involved in an exciting project to develop Dudley as a virtual reality hub for the West Midlands region, where I ran virtual reality research projects and created innovative teaching projects using VR technology." />
        </ColumnSection>
        <Spacer />
        <ColumnSection>
          <Institution name="The Dudley Group of Hospitals NHS Foundation Trust" />
          <Location location="Dudley, West Midlands" />
          <Spacer />
          <Dates dates="August 2022 – August 2023" />
        </ColumnSection>
      </SectionWithImage>

      {/* FY3 year section */}
      <SectionWithImage image={FY3}>
        <ColumnSection>
          <Description text="FY3 year" />
          <Description text="During my FY3 year, I worked in neurosurgery at Salford Royal Hospital for three months. I gained hands-on experience in this specialty and also worked across other medical specialties, such as cardiology, stroke medicine, elderly care medicine, acute medicine, and COVID-19. I also had the chance to take a break from clinical practice to travel for six weeks around Mexico, Cuba, and Canada, which was an enjoyable opportunity to explore new cultures. At the end of the year, I worked in critical care for three months. I used this year to help give me some direction in choosing which path to pursue for specialty training." />
        </ColumnSection>
        <Spacer />
        <ColumnSection>
          <Institution name="Northern Care Alliance NHS Foundation Trust, Manchester University Hospitals NHS Foundation Trust" />
          <Location location="Salford, Greater Manchester, Manchester, Greater Manchester" />
          <Spacer />
          <Dates dates="August 2021 – August 2022" />
        </ColumnSection>
      </SectionWithImage>

      {/* Foundation Doctor section */}
      <SectionWithImage image={FY}>
        <ColumnSection>
          <Description text="Foundation Doctor" />
          <Description text="I completed my foundation training at the Pennine Acute Hospitals NHS Trust, where I had rotations in adult psychiatry, stroke medicine, general practice, emergency medicine, and acute medicine. My training during this time was impacted by the COVID-19 pandemic." />
        </ColumnSection>
        <Spacer />
        <ColumnSection>
          <Institution name="Pennine Acute Hospitals NHS Trust" />
          <Location location="Oldham, Greater Manchester, Bury, Greater Manchester, Rochdale, Greater Manchester" />
          <Spacer />
          <Dates dates="August 2019 – August 2021" />
        </ColumnSection>
      </SectionWithImage>

      {/* Bachelor of Science section */}
      <SectionWithImage image={BSc}>
        <ColumnSection>
          <Description text="Bachelor of Science in Medical Sciences (Neuroscience) · First Class Honours" />
          <Description text="Modules – pharmacology & medicinal chemistry, medical genomics, frontiers in neuroscience, and neuropharmacology. Dissertation – The Effect of ML218, a T-Type Calcium Channel Blocker, upon Interictal-Like Bursting in the CA3 Region of the Mouse Hippocampus" />
        </ColumnSection>
        <Spacer />
        <ColumnSection>
          <Institution name="The University of Exeter" />
          <Location location="Exeter, Devon" />
          <Spacer />
          <Dates dates="August 2018 – August 2019" />
        </ColumnSection>
      </SectionWithImage>

      {/* Bachelor of Medicine, Bachelor of Surgery section */}
      <SectionWithImage image={BMBS}>
        <ColumnSection>
          <Description text="Bachelor of Medicine, Bachelor of Surgery" />
          <Description text="During my time at the University of Exeter, I worked and lived in Exeter, Devon and Truro, Cornwall. I had a fantastic time at medical school, made lifelong friends, experienced living in amazing places, and met my future wife. If you are interested in studying medicine at the University of Exeter, then feel free to contact me and I will happily discuss my experiences!" />
        </ColumnSection>
        <Spacer />
        <ColumnSection>
          <Institution name="The University of Exeter" />
          <Location location="Exeter, Devon & Truro, Cornwall" />
          <Spacer />
          <Dates dates="August 2012 – August 2019" />
          <Spacer />
          <Spacer />
        </ColumnSection>
      </SectionWithImage>
    </div>
  );
}
