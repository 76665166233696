import React from "react";

import clinical_compass from "./../assets/project_images/clinical_compass.png"
import CodeMed from "./../assets/course_images/CodeMed.jpeg"
import opportunity_theatre from "./../assets/project_images/opportunity_theatre.png"
import realeyes from "./../assets/project_images/realeyes.png"

const SectionWithImage = ({ image, children }) => (
  <div className="wp-block-group alignfull has-background">
    <div className="container mx-auto px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="flex flex-col sm:flex-row items-center justify-center">
          <img src={image} alt="Section Image" className="w-full sm:w-40 h-auto mb-4 sm:mr-4" />
          <div>{children}</div>
        </div>
      </div>
    </div>
  </div>
);

const ColumnSection = ({ children }) => (
  <div className="wp-block-jetpack-layout-grid column1-desktop-grid__span-6 column1-desktop-grid__start-2 column1-desktop-grid__row-1 column2-desktop-grid__span-3 column2-desktop-grid__start-9 column2-desktop-grid__row-1 column1-tablet-grid__span-5 column1-tablet-grid__row-1 column2-tablet-grid__span-2 column2-tablet-grid__start-7 column2-tablet-grid__row-1 column1-mobile-grid__span-4 column1-mobile-grid__row-1 column2-mobile-grid__span-4 column2-mobile-grid__row-2">
    {children}
  </div>
);

const Description = ({ text }) => (
  <p className="text-lg">{text}</p>
);

const Dates = ({ dates }) => <p className="">{dates}</p>;

const Spacer = () => <div className="h-5"></div>;

export default function MedicalCareer() {
  return (
    <div className="text-slate-200">
      {/* CodeMed section */}
      <SectionWithImage image={CodeMed}>
        <ColumnSection>
          <Description text="Tutor at CodeMed" />
          <Description text="In 2020, I completed CodeMed’s Java Course and was thrilled to be invited back as a course tutor. Since then, I’ve had the pleasure of working with over 100 healthcare professionals, teaching them how to use programming to solve commonly-encountered problems in the clinical environment. It’s been incredibly rewarding to share my knowledge and help others learn a valuable skill." />
        </ColumnSection>
        <Spacer />
        <ColumnSection>
          <Spacer />
          <Dates dates="May 2022 - present" />
        </ColumnSection>
      </SectionWithImage>

      {/* Clinical Compass section */}
      <SectionWithImage image={clinical_compass}>
        <ColumnSection>
          <Description text="Clinical Compass" />
          <Description text="I'm currently working with the media team at the Dudley Group NHS Foundation Trust to develop Clinical Compass. The software is designed to store the hospital's clinical guidelines in a user-friendly format, utilising machine learning algorithms to organise the information. By implementing this technology, we've been able to positively impact the usability of the system and enhance the user experience for clinicians. It's exciting to be a part of a project that has the potential to improve patient outcomes and streamline healthcare operations at the hospital. Check it out at clinicalcompass.co.uk" />
        </ColumnSection>
        <Spacer />
        <ColumnSection>
          <Dates dates="2022" />
        </ColumnSection>
      </SectionWithImage>

      {/* RealEyes section */}
      <SectionWithImage image={realeyes}>
        <ColumnSection>
          <Description text="RealEyes" />
          <Description text="RealEyes is a virtual reality teaching project that I have developed  alongside colleagues at the Dudley Group NHS Foundation Trust and Health Education England. With 360-degree video and multiple perspectives, it is a way for healthcare professionals meet learning objectives surrounding ethical and professional responsibilities in a fun, immersive and innovative way. If you are interested in finding out more then please visit RealEyesVR.co.uk" />
        </ColumnSection>
        <Spacer />
        <ColumnSection>
          <Dates dates="2022" />
        </ColumnSection>
      </SectionWithImage>

      {/* Opportunity Theatre section */}
      <SectionWithImage image={opportunity_theatre}>
        <ColumnSection>
          <Description text="Opportunity Theatre" />
          <Description text="OpportunityTheatre is a platform that I have designed to connect healthcare professionals with opportunities around their hospital to support with competency assessments. OpportunityTheatre enables users to select their preferred hospitals, set notification preferences, and receive timely updates on opportunities within their chosen healthcare institutions. If you are interested in finding out more then please visit opportunitytheatre.co.uk" />
        </ColumnSection>
        <Spacer />
        <ColumnSection>
          <Dates dates="2023" />
        </ColumnSection>
      </SectionWithImage>
    </div>
  );
}
