import React from "react";

export default function ComputerScience() {
  return (
    <section className="min-h-screen flex items-center justify-center">
      <div className="container mx-auto px-4">
        <div className="flex flex-col items-center justify-center">
          <div className="mb-8 flex space-x-4">
            <a href="/computer-science/education" className="bg-slate-800 text-slate-200 px-6 py-3 rounded-xl hover:bg-slate-900 transition duration-300 text-center align-middle flex items-center justify-center">Computer science education</a>
            <a href="/computer-science/projects" className="bg-slate-800 text-slate-200 px-6 py-3 rounded-xl hover:bg-slate-900 transition duration-300 text-center align-middle flex items-center justify-center">Computer science projects</a>
          </div>
        </div>
      </div>
    </section>
  );
}
