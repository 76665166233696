import React from "react";
import SocialMediaBanner from "./partials/social_media_banner";
import photo from "./../assets/photos/main_page_photo.jpeg";
import panoramaPhoto from "./../assets/photos/panorama_photo.png"
import finlandPhoto from "./../assets/photos/finland_photo.png"
import snowdonBeachPhoto from "./../assets/photos/snowdon_beach_photo.png"
import weddingPhoto from "./../assets/photos/wedding_photo.png"
import "./../App.css";

export default function Home() {
  return (
    <div>
      <HeroSection />
      <ServicesSection />
      <IntroductionSection />
    </div>
  );
}

const HeroSection = () => {
  return (
    <section
      className="text-slate-200 py-20 px-6 bg-cover bg-center flex items-center min-h-screen relative"
      style={{ backgroundImage: `url(${photo})` }}
    >
      <div className="absolute inset-0 bg-black opacity-50"></div>
      <div className="container mx-auto text-center relative z-10">
        <h1 className="text-4xl font-bold mb-4">
          Dr Matthew Whallett BMBS BSc
        </h1>
        <p className="text-lg">Junior Doctor & Developer</p>
      </div>
    </section>
  );
};

const IntroductionSection = () => {
  return (
    <section className="flex flex-col justify-center px-6 items-center text-slate-200">
      <img src={panoramaPhoto} alt="Panorama" className="mb-8 opacity-75" />
      <div className="container mx-auto text-center">
        <h2 className="text-3xl font-bold mb-4">About Me</h2>
        <p className="text-lg mb-8">
          I have lived in Worcestershire, Devon, Cornwall & Greater Manchester.
          I love the great outdoors, particularly anything to do with snow or
          water, and I'm always up for exploring new places!
        </p>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-12">
          <div className="aspect-w-3 aspect-h-4">
            <img src={finlandPhoto} alt="Finland" className="object-cover w-full h-full opacity-75" />
          </div>
          <div className="aspect-w-3 aspect-h-4">
            <img src={snowdonBeachPhoto} alt="Snowdon Beach" className="object-cover w-full h-full opacity-75" />
          </div>
          <div className="aspect-w-3 aspect-h-4">
            <img src={weddingPhoto} alt="Wedding" className="object-cover w-full h-full opacity-75" />
          </div>
        </div>
        <div className="w-60 border-b border-slate-100 mx-auto mt-8 mb-6"/>
        <h3 className="text-xl font-bold mb-2">Follow Me On</h3>
        <div className="flex justify-center">
          <SocialMediaBanner />
        </div>
        <br />
        <div className="w-60 border-b border-slate-100 mx-auto mb-6"/>
      </div>
    </section>
  );
};


const ServicesSection = () => {
  return (
    <section className="flex px-6 justify-center items-center py-10 text-slate-200">
      <div className="container mx-auto text-center">
        <h2 className="text-2xl mb-4">
          Internal Medical Training Doctor at Sandwell and West Birmingham
          Hospitals NHS Trust
        </h2>
        <p className="text-lg mb-4">Freelance developer</p>
        <p className="text-lg">
          Course Tutor at{" "}
          <u>
            <a
              href="https://codemed.co.uk/"
              target="_blank"
              rel="noreferrer noopener"
              className="hover:text-slate-400"
            >
              CodeMed
            </a>
          </u>
        </p>
      </div>
    </section>
  );
};
